import { ChangeEvent, FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import CountrySelector from "../input/CountrySelector";
import LanguageSelector from "../input/LanguageSelector";
import PinInput from "../input/PinInput";
import useAPI from "../../hooks/useAPI";

const SignUpForm: FC = () => {
  const { referralCodeFromURL } = useParams();

  const { accountsAPI } = useAPI();

  const [language, setLanguage] = useState("en");
  const [country, setCountry] = useState("NG");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [hasReferralCode, setHasReferralCode] = useState(false);
  const [referralCode, setReferralCode] = useState<string[]>(["", "", "", ""]);
  const [pin, setPin] = useState<string[]>(["", "", "", ""]);
  const [acceptsToS, setAcceptsToS] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationCodeModalOpen, setConfirmationCodeModalOpen] =
    useState(false);

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
  };

  const reserveAccount = async () => {
    try {
      const response = await accountsAPI.reserveAccount({
        referred_by: hasReferralCode ? referralCode.join("") : undefined,
        phone_number: phoneNumber,
        password: pin.join(""),
        name: name,
        location: country,
        language: language,
      });

      if (response.status === 200) {
        setConfirmationCodeModalOpen(true);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data.detail;
      }
      setErrorMessage(errorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    if (referralCodeFromURL) {
      if (referralCodeFromURL.length === 4) {
        setReferralCode(referralCodeFromURL.split(""));
        setHasReferralCode(true);
      }
    }
  }, [referralCodeFromURL]);

  return (
    <>
      <CountrySelector country={country} setCountry={setCountry} />
      <Box mb={2} />
      <LanguageSelector language={language} setLanguage={setLanguage} />
      <Box mb={2} />
      <MuiTelInput
        label="Phone number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        defaultCountry={"NG"}
        onlyCountries={["GB", "GM", "ML", "NG"]}
        fullWidth
      />
      <Box mb={2} />
      <TextField
        label="Name"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Box mb={2} />
      <Box display="flex" justifyContent="center">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasReferralCode}
                onChange={(event) => setHasReferralCode(event.target.checked)}
              />
            }
            label="I have a referral code"
          />
        </FormGroup>
      </Box>
      {hasReferralCode && (
        <PinInput
          label="Ref code"
          pin={referralCode}
          setPin={setReferralCode}
        />
      )}
      <Box mb={2} />
      <PinInput label="PIN" pin={pin} setPin={setPin} />
      <Box display="flex" justifyContent="center" mt={1}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptsToS}
                onChange={(event) => setAcceptsToS(event.target.checked)}
              />
            }
            label="I accept the terms of service"
          />
        </FormGroup>
      </Box>
      <Box display="flex" justifyContent="center" mt={1} mb={1}>
        <Typography color="error">{errorMessage}</Typography>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        sx={{ fontWeight: 700 }}
        disabled={!phoneNumber || pin.includes("") || !acceptsToS}
        onClick={reserveAccount}
      >
        Create
      </Button>
      <Dialog open={confirmationCodeModalOpen} maxWidth={"xs"}>
        <ConfirmationCodeForm phoneNumber={phoneNumber} />
      </Dialog>
    </>
  );
};

const ConfirmationCodeForm: FC<{
  phoneNumber: string;
}> = ({ phoneNumber }) => {
  const navigate = useNavigate();

  const { accountsAPI } = useAPI();

  const [code, setCode] = useState<string[]>(["", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");

  const claimAccount = async () => {
    try {
      const response = await accountsAPI.claimAccount({
        phone_number: phoneNumber,
        verification_code: code.join(""),
      });

      if (response.status === 200) {
        alert("Your account is ready! Please log in");
        navigate("/login");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <Box>
      <Typography variant="h5" align="center" mt={2} mb={2}>
        A confirmation code has been sent to your phone number. Please enter it
        below:
      </Typography>
      <PinInput label="Code" pin={code} setPin={setCode} />
      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        <Typography color="error">{errorMessage}</Typography>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        sx={{ fontWeight: 700 }}
        disabled={code.includes("")}
        onClick={claimAccount}
      >
        Create
      </Button>
    </Box>
  );
};

export default SignUpForm;
