import { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import PinInput from "../input/PinInput";
import useAPI from "../../hooks/useAPI";

const SignInForm: FC = () => {
  const navigate = useNavigate();

  const { rememberMe, setRememberMe, signIn, profile } = useAPI();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [pin, setPin] = useState<string[]>(["", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
  };

  const handleLogin = async () => {
    try {
      await signIn(phoneNumber.replace(/\s/g, ""), pin.join(""));
      navigate("/");
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data.detail;
      }
      setErrorMessage(errorMessage);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    if (profile) {
      navigate("/");
    }
  }, [profile, navigate]);

  return (
    <>
      <MuiTelInput
        label="Phone number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        defaultCountry={"NG"}
        onlyCountries={["GB", "GM", "ML", "NG"]}
        fullWidth
      />
      <Box mb={4} />
      <PinInput label="PIN" pin={pin} setPin={setPin} />
      <Box mb={1} />
      <Box display="flex" justifyContent="flex-start">
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
          }
          label={"Remember me"}
        />
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography color="error">{errorMessage}</Typography>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        sx={{ fontWeight: 700 }}
        onClick={handleLogin}
        disabled={!phoneNumber || pin.includes("")}
      >
        Login
      </Button>
    </>
  );
};

export default SignInForm;
