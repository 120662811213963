import { FC, useState } from "react";

import { Box, Button, TextField } from "@mui/material";

import useAPI from "../../hooks/useAPI";
import hasPermission from "../../utilities/access";

const ChatInput: FC<{
  sendMessage: (content: string) => void;
  closeConversation: () => void;
}> = ({ sendMessage, closeConversation }) => {
  const { profile } = useAPI();

  const [message, setMessage] = useState("");

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();

        if (message.trim() === "") {
          return;
        }

        triggerSendMessage();
      }
    }
  };

  const triggerSendMessage = () => {
    sendMessage(message);
    setMessage("");
  };

  return (
    <Box display="flex" flexDirection="column">
      {hasPermission(profile!.access_level, "doctor") ? (
        <Box display="flex" flexDirection="row" mb={1}>
          <Button variant="contained" onClick={closeConversation} color="info">
            End consultation
          </Button>
        </Box>
      ) : null}
      <Box display="flex" flexDirection="row">
        <Box width="100%" mr={2}>
          <TextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
            multiline
            maxRows={4}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ color: "white" }}
          onClick={triggerSendMessage}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default ChatInput;
