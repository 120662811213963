import { Dispatch, FC, SetStateAction } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const CountrySelector: FC<{
  country: string;
  setCountry: Dispatch<SetStateAction<string>>;
}> = ({ country, setCountry }) => {
  const handleChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="country-select-label">Country</InputLabel>
      <Select
        labelId="country-select-label"
        id="country-select"
        value={country}
        onChange={handleChange}
        label="Country"
      >
        <MenuItem value="GB">United Kingdom</MenuItem>
        <MenuItem value="GM">The Gambia</MenuItem>
        <MenuItem value="ML">Mali</MenuItem>
        <MenuItem value="NG">Nigeria</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CountrySelector;
