const CONFIG = {
  // apiHost: "http://localhost:8000/api",
  // apiHost:
  //   "https://tkoj2d4x7k4txgploxl2tq4bai0jradj.lambda-url.eu-west-2.on.aws/api",
  apiHost:
    "https://mqknlrdbqo73e46hnqrvt7lmoe0pvmgt.lambda-url.eu-west-2.on.aws/api",
  loadingScreenTime: 500, // Milliseconds.
  vapidPublicKey:
    "BJ0YhnjfCNtyVrkskQCME42bBZXDTqaqFGgd9YlwurdSmJI7ns-yprrms0umlGqGN9lyztYihszhZ4_OXxU3Cps",
};

export default CONFIG;
